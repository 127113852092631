import * as React from 'react';
import Layout from '../../components/layout';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

const BlogPost = ({ data }) => {
   console.log('data', data);
   return (
      <>
         <Layout pageTitle={data.mdx.frontmatter.title}>
            <p>{data.mdx.frontmatter.date}</p>
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
         </Layout>
      </>
   );
};

// export const query = graphql`
//    query ($id: String) {
//       allFile(
//          filter: {
//             childMdx: { id: { eq: $id } }
//             sourceInstanceName: { eq: "blogPosts" }
//          }
//       ) {
//          nodes {
//             childMdx {
//                frontmatter {
//                   date(formatString: "MMMM D, YYYY")
//                   title
//                }
//                id
//                body
//             }
//          }
//       }
//    }
// `;

export const query = graphql`
   query ($id: String) {
      mdx(id: { eq: $id }) {
         frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
         }
         body
      }
   }
`;

export default BlogPost;

// export const query = graphql`
//    query ($id: String) {
//       mdx(id: { eq: $id }) {
//          frontmatter {
//             title
//             date(formatString: "MMMM D, YYYY")
//          }
//          body
//       }
//    }
// `;
